<template>
  <div id="datos-vehiculo">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-text style="background: #eee">
          <v-row class="mt-2">
            <v-col cols="12" md="12" sm="12">
              <base-material-card color="primary" class="px-5 py-3">
                <template v-slot:heading>
                  <div class="text-h5 font-weight-light my-2">
                    {{
                      form.numero
                        ? "Datos del Vehiculo: " + form.numero
                        : "Datos del Vehiculo"
                    }}
                    {{
                      form.fecha ? " - Fecha: " + formatDate(form.fecha) : null
                    }}
                  </div>
                </template>
                <v-card-text>
                  <v-row class="text-left pa-1">
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"
                            ><p>Año del modelo:</p>
                          </v-col>
                          <v-col cols="12" sm="3" style="margin-top: -37px">
                            <v-text-field
                              class="text-right font-weight-bold"
                              v-model="form.aniomodelo"
                              maxlength="4"
                              label=""
                              :rules="[
                                $rulesRequerido,
                                $rulesEnterosPositivos,
                                $rulesAnioMin,
                                $rulesAnioMax,
                              ]"
                              style="height: 28px"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" sm="12" class="py-0">
                            <v-checkbox
                              class="mt-4"
                              v-model="form.utilizarAnioInscripcion"
                              label="Utilizar año inscripción"
                              filled
                              dense
                              @change="setAnio()"
                              style="margin-top: 9px !important"
                            ></v-checkbox
                          ></v-col>
                        </v-row> </v-sheet
                    ></v-col>

                    <v-col cols="12" sm="6" class="centered-input pa-0 pr-2">
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> Tipo Certificado:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.tipo_certificado }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                  </v-row>
                  <v-row class="text-left pa-1">
                    <v-col cols="12" sm="6" class="centered-input pa-0 pr-2">
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> Núm. Certificado:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.numero_certificado }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> Valuación:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.valuacion }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                  </v-row>

                  <v-row class="text-left pa-1">
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3">Motor N°:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.motor }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3">Chasis N°:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.chasis }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                  </v-row>

                  <v-row class="text-left pa-1">
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> MTM / FMM:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">{{ form.mtm }}</div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                    <v-col cols="12" sm="6" class="centered-input pa-0 pr-2">
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3">Marca:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.marca }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                  </v-row>
                  <v-row class="text-left pa-1">
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> Modelo:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.modelo }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> Tipo:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                            :style="{
                              color: $cv('principal'),
                            }"
                          >
                            <div class="border-bottom-cell">
                              {{ form.tipo }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                  </v-row>

                  <v-row class="text-left pa-1">
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> Marca Motor:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.marca_motor }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> Marca del Chasis:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold font-italic"
                          >
                            <div class="border-bottom-cell">
                              {{ form.marca_del_chasis }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-sheet></v-col
                    >
                  </v-row>

                  <v-row class="text-left pa-1">
                    <v-col cols="12" sm="6" class="centered-input pa-0 pr-2">
                      <v-sheet class="mx-auto pa-1" elevation="1" height="35">
                        <v-row>
                          <v-col cols="12" sm="3"> Comerciante </v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold"
                            style="margin-top: -24px"
                          >
                            <v-autocomplete
                              :items="comerciantes"
                              item-value="id"
                              item-text="nombre"
                              label=""
                              clearable
                              variant="solo"
                              v-model="form.comerciante"
                              :rules="[$rulesRequerido]"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                        </v-row> </v-sheet
                    ></v-col>
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                      >
                        <v-row>
                          <v-col cols="12" sm="3"> Tipo de Uso:</v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold"
                            style="margin-top: -24px"
                          >
                            <v-autocomplete
                              :items="tipo_de_usos"
                              item-value="id"
                              item-text="detalle"
                              label=""
                              clearable
                              variant="solo"
                              v-model="form.tipo_de_uso_id"
                              :rules="[$rulesRequerido]"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                        </v-row> </v-sheet
                    ></v-col>
                  </v-row>
                  <v-row class="text-left pa-1">
                    <v-col cols="12" sm="6" class="centered-input pa-0 pr-2">
                      <v-sheet class="mx-auto pa-1" elevation="1" height="35">
                        <v-row>
                          <v-col cols="12" sm="3">Placa de traslado </v-col>
                          <v-col
                            cols="12"
                            sm="9"
                            class="text-right font-weight-bold"
                            style="margin-top: -24px"
                          >
                            <v-autocomplete
                              :items="itemsSN"
                              item-value="id"
                              item-text="nombre"
                              label=""
                              clearable
                              v-model="form.placa_de_traslado"
                              :rules="[$rulesRequerido]"
                              return-object
                            ></v-autocomplete>
                          </v-col>
                        </v-row> </v-sheet
                    ></v-col>
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      class="centered-input pa-0 pr-2"
                    >
                      <v-sheet
                        class="rounded mx-auto transition-swing pa-1 blue-grey--text darken-4"
                        elevation="1"
                        height="35"
                        style="background-color: #eee"
                      >
                        <v-row> </v-row> </v-sheet
                    ></v-col>
                  </v-row>
                  <v-row> </v-row>
                </v-card-text>
              </base-material-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-right" cols="12" md="12" sm="12">
              <v-btn color="red" @click="$exit(idTramite)">Salir</v-btn>
              <v-btn color="primary-2" @click="back()">Atras</v-btn>
              <v-btn color="primary" @click="next()">Siguiente</v-btn>
            </v-col>
          </v-row>
          <br />
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.border-bottom-cell {
  border-bottom: 2px solid #ccc; /* Ajusta el color y el grosor según tus preferencias */
  padding-bottom: 4px; /* Espacio opcional en la parte inferior */
  font-family: "Questrial";
}
.right-input >>> input {
  text-align: right;
}
.chip-indicadores {
  color: "blue-grey--text darken-4";
  font-size: 14px;
  /*font-family: 'Material Icons';*/
  /*font-weight: bold;*/
  font-style: normal;
}

.primary-change {
  color: #fff !important;
  /*background-color: #a7a8a8 !important;*/
  background-color: #105cfa !important;
  font-size: 20px;
  font-weight: bold;
  height: 70px !important;
  display: flex;
  align-items: center;
}
.col-f12 {
  text-align: center;
  font-size: 2rem;
  /*border-radius: 28px;*/
  font-style: #a0a0a0 !important;
  color: #a0a0a0 !important;
  font-weight: 500 !important;
  border-bottom: solid;
  border-color: #011f5b;
  /*border-radius: 28px;*/
  /*background: #eee;*/
  padding: 8px 0px 23px 17px;
  height: 55px;
  margin-top: 7px;
  margin-left: 7px !important;
  margin-right: 15px;
}
.centered-input >>> input {
  text-align: center;
  font-size: 1.5rem;
  color: #105cfa !important;
  margin-top: 16px;
  border-bottom: solid;
  border-color: #011f5b;
}
.label {
  padding: 6px 2px 3px 8px;
  border-radius: 6px;
  font-weight: bold;
}
</style>
<script>
export default {
  name: "DatosVehiculo",
  components: {},
  props: {
    datosVehiculo: { Object, default: null },
    idTramite: { Number, default: null },
    tramite: { Object, default: null },
  },
  data: () => ({
    snackbar: false,
    color: "success",
    timeout: 4000,
    text: "Se ha registrado una nueva solicitud",
    tipo_de_usos: [],
    comerciantes: [
      { id: 1, nombre: "Peugeot Drago Beretta" },
      { id: 2, nombre: "VW Espasa" },
      { id: 3, nombre: "VW Autotag" },
      { id: 4, nombre: "FIAT autonovo" },
    ],

    rules: {
      anioMin: (value) =>
        parseInt(value) <= 2000 || "El año debe ser mayor a 2000",
      anioMax: (value) =>
        parseInt(value) >= parseInt(new Date().getFullYear()) ||
        "El año no puede ser mayor a " + new Date().getFullYear(),
    },

    valid: true,

    form: {},
    itemsSN: [
      { id: "S", nombre: "SI" },
      { id: "N", nombre: "NO" },
    ],
    tipo_certificados: [],

    arrayVehiculo: [
      {
        valuacion: "8.500.000",
        chasis: "000678678672345679",
        motor: "120000453400002345679",
        mtm: "100 21 2",
        marca: "VOLKSWAGEN",
        marca_motor: "VOLKSWAGEN",
        marca_del_chasis: "VOLKSWAGEN",
        tipo: "Sedan 5 puertas",
        modelo: "GOL",
      },
      {
        valuacion: "9.800.000",
        chasis: "0023FD4HNJ234345679",
        motor: "120000453400002345679",
        mtm: "103 02 7",
        marca: "FIAT",
        marca_motor: "FIAT",
        marca_del_chasis: "FIAT",
        tipo: "Sedan 5 puertas",
        modelo: "CRONOS",
      },
      {
        valuacion: "11.200.000",
        chasis: "24HM235N0Z0000X",
        motor: "043HH34563HB4Z3ZZ",
        mtm: "503 98 17",
        marca: "PEUGEOT",
        marca_motor: "PEUGEOT",
        marca_del_chasis: "PEUGEOT",
        tipo: "Sedan 5 puertas",
        modelo: "208 GTI",
      },
    ],
  }),

  methods: {
    setAnio() {
      if (this.form.utilizarAnioInscripcion) {
        this.form.aniomodelo = new Date().getFullYear();
        this.$forceUpdate();
      }
    },
    back() {
      this.$emit("send-message", "2");
    },
    next() {
      //this.form.tipo_operacion_id = 1;
      this.$emit("send-message", "4", "3", this.form);

      /*//if (this.$refs.form.validate() && this.primeravez) {
																																								      if (this.$refs.form.validate()) {
																																								        this.$emit("send-message", "2", "1", this.form);
																																								        this.primeravez = false;
																																								      } else {
																																								        this.snackbar = true;
																																								        this.text = "Verifique los datos ingresados y/o campos obligatorios";
																																								        this.color = "error";
																																								      }*/
    },
    setDatosPrueba() {
      this.form.numero_certificado = "XX-XXXXXXXXXXX/XX";
      this.form.aniomodelo = 2022;
      this.form.utilizarAnioInscripcion = false;
      this.form.tipo_certificado = "aca va el tipo";
      this.form.valuacion = "6.500.000";
      this.form.chasis = "000678678672345679";
      this.form.motor = "120000453400002345679";
      this.form.mtm = "100 21 2";
      this.form.marca = "VOLKSWAGEN";
      this.form.marca_motor = "VOLKSWAGEN";
      this.form.marca_del_chasis = "VOLKSWAGEN";
      this.form.tipo = "Sedan 5 puertas";
      this.form.modelo = "GOL";
      this.form.comerciante = { id: 1, nombre: "Peugeot Drago Beretta" };
      this.form.tipo_de_uso_id = { id: 2, nombre: "No Declarado" };
      this.placa_de_traslado = { id: "S", nombre: "SI" };
    },

    async getDatosVehiculo() {
      await this.$sleep(1000);
      Swal.close();
      let aleatorio =
        this.arrayVehiculo[
          Math.floor(Math.random() * this.arrayVehiculo.length)
        ];
      //console.log(aleatorio);
      this.form = aleatorio;

      this.form.tipo_certificado_id = Number(this.tramite.tipo_certificado_id);
      this.form.numero_certificado = this.tramite.numero_certificado;

      let id = this.tramite.tipo_certificado_id;
      this.tipo_certificado = this.tipo_certificados.filter(
        (item) => item.id === this.tramite.tipo_certificado_id
      );

      this.form.tipo_certificado =
        typeof this.tipo_certificado[0] !== "undefined"
          ? this.tipo_certificado[0].nombre
          : "";
    },
  },
  computed: {},
  created() {
    //---------------CASO DE PRUEBA---------------------//
    //this.setDatosPrueba();
    //---------------CASO DE PRUEBA---------------------//
    //this.$getListForSelect("tipo_de_usos");
    this.$getData(["tipo_de_usos"]);

    this.valid = true;
  },

  watch: {},
  mounted() {
    this.$getData(["tipo_certificados"]);

    if (this.datosVehiculo != null && Object.keys(this.datosVehiculo).length) {
      this.form = this.datosVehiculo;
    } else {
      this.getDatosVehiculo();
    }

    console.log("Componente Datos Vehiculo creado");
  },
};
</script>
